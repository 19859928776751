<template>
	<div class="container">
		<router-view v-if="getAvailableMethodsFetchStatus.success" />
		<div v-if="getAvailableMethodsFetchStatus.loading" class="center">
			<Loader />
		</div>
		<div v-if="getAvailableMethodsFetchStatus.error" class="error-container">
			<ErrorCard :error="getAvailableMethodsFetchStatus.error"/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/shared/loader/Loader';
import ErrorCard from '@/shared/error-card/ErrorCard';

export default {
	name: 'Deposit',
	components: { ErrorCard, Loader },
	computed: {
		...mapGetters(['getAvailableMethodsFetchStatus']),
	},
	created() {
		this.fetchAvailablePaymentMethods();
	},
	methods: {
		...mapActions(['fetchAvailablePaymentMethods']),
	},
};
</script>
<style lang="scss">
.container {
	flex: 1;
}
.center {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.error-container {
	margin-top: 4rem;
}
</style>
